h2.pageTitle {
  @apply text-center
  text-xl md:text-2xl
  font-semibold
  text-gray-800
  border-solid
  border-b
  border-gray-800
  py-4
  mb-2;
}

h3.pageTitle {
  @apply text-center
  text-xl md:text-xl
  font-semibold
  text-gray-800
  py-4;
}

h3.title {
  @apply text-base
  border-solid
  border-b
  border-gray-800
  py-2
  mt-4
  mb-2;
}

p.content {
  @apply text-sm leading-6;
}
p.contentml2 {
  @apply text-sm leading-6 ml-2;
}

ul.list {
  @apply text-sm list-decimal list-inside leading-6 p-1 md:p-2;
}

span.smList {
  @apply text-sm leading-6 p-1 md:p-2;
}

.bold {
  @apply font-bold;
}
.boldmr2 {
  @apply font-bold mr-2;
}

.red {
  @apply text-red-600;
}

.mt4 {
  @apply mt-4;
}

.externalLink {
  @apply text-red-600 hover:text-red-400;
}

.conditionMenu {
  width: 50%;
  margin-bottom: 0.8rem;
  @apply flex
  md:justify-center
  text-gray-800 hover:text-red-600
  md:bg-gray-300 md:hover:bg-gray-400
  md:py-4
  md:px-4
  md:m-1
  md:rounded-md
  md:w-auto;
}

.conditionMenuActive {
  width: 50%;
  margin-bottom: 0.8rem;
  @apply flex
  md:justify-center
  text-red-600
 md:bg-gray-400
  md:py-4
  md:px-4
  md:m-1
  md:rounded-md
  md:w-auto;
}

table.tbl {
  @apply w-full
  border
  border-solid
  border-gray-400;
  border-collapse: collapse;
  border-spacing: 0;
  word-break: break-all;
}
.tbl th {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    bg-gray-200;
}
.tbl tr {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm;
}
.tblTR :nth-child(1) {
  width: 70%;
  @apply md:w-3/4;
}
.tblTR :nth-child(2) {
  width: 30%;
  @apply md:w-1/4 text-center;
}

.tblTR2 :nth-child(1) {
  width: 70%;
}
.tblTR2 :nth-child(n + 2) {
  width: 15%;
  @apply text-center;
}

.tblTR3 th:nth-child(1) {
  width: 70%;
  @apply text-center;
}
.tblTR3 th:nth-child(n + 2) {
  width: 15%;
  @apply text-center;
}

.tblTR3 td {
  width: 15%;
  @apply text-center;
}

.tbl td {
  @apply border
    border-solid
    border-gray-400
    p-1;
}
.tblBgc {
  @apply bg-gray-300 text-center;
}

table.tblHalf {
  @apply w-full
  border
  border-solid
  border-gray-400;
  border-collapse: collapse;
  border-spacing: 0;
  word-break: break-all;
}
.tblHalf tr {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm;
}
.tblHalf th {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    text-center
    bg-gray-200;
}
.tblHalfTR th,
td {
  width: 50%;
}
.tblHalf td {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    text-center;
}

table.tblCharge {
  @apply w-full
  border
  border-solid
  border-gray-400;
  border-collapse: collapse;
  border-spacing: 0;
  word-break: break-all;
}
.tblCharge th {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    bg-gray-200;
}
.tblCharge tr {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm;
}
.tblCharge td {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    text-center;
}
.tblChargeTR th,
td {
  width: 25%;
  text-align: center;
}

.tblChargeTRL td {
  @apply text-left;
}
.tblChargeTRL p {
  @apply leading-6 text-red-600 ml-1;
}

table.tblCharge3 {
  @apply w-full
  border
  border-solid
  border-gray-400;
  border-collapse: collapse;
  border-spacing: 0;
  word-break: break-all;
}
.tblCharge3 th {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    bg-gray-200;
}
.tblCharge3 tr {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm;
}
.tblCharge3 td {
  @apply border
    border-solid
    border-gray-400
    p-1
    text-sm
    text-center;
}

.tblCharge3TRTH {
  text-align: center;
}
.tblCharge3TRTH :first-child :last-child {
  width: 25%;
}
.tblCharge3TRTH :nth-child(2) {
  width: 50%;
}

.tblCharge3TR :first-child {
  text-align: left;
}
.tblCharge3TR3 :nth-child(2) {
  text-align: left;
}
.tblCharge3TRC {
  text-align: center;
}

.tblCharge3TRL td {
  text-align: left;
}
.tblCharge3TRL p {
  @apply leading-6 text-red-600 ml-1;
}

table.tblCompany {
  @apply w-full;
  border-collapse: collapse;
  border-spacing: 0;
  word-break: break-all;
}
.tblCompanyTD {
  @apply w-full
    flex
    flex-col md:flex-row;
}
.tblCompanyTD :first-child {
  @apply w-full md:w-1/4
    p-0 md:p-2
    bg-gray-200
    flex items-center justify-start md:justify-center;
}
.tblCompanyTD :last-child {
  @apply w-full md:w-3/4
    px-2 md:px-3
    py-2
    text-left
    leading-5
    flex items-center;
}

.tblCompany tr {
  @apply text-sm
    bg-gray-100
    border
    border-solid;
  border-color: #f8fafc;
}

.helpQAarea {
  @apply mt-5 space-y-5;
}

.helpQA p {
  color: #282846;
}

.helpQA div {
  background-color: #eeeeee;
  @apply px-4 py-4 mt-2.5 rounded-lg;
}

h3.helpTitle {
  color: #282846;
  @apply text-base
  font-semibold
  border-solid
  border-b
  border-gray-800
  py-2
  mt-4
  mb-2;
}
