.commentArea {
  color: #282846;
  border-color: #c7c7c7;
  @apply my-5 bg-white text-base py-4 px-4 rounded-lg border-2;
}

.commentArea p {
  @apply max-w-4xl mx-auto;
}

.cart {
  border-color: #282846;
  @apply bg-white rounded-lg border-2 shadow-lg;
}

.cartTitle {
  background-color: #282846;
  @apply text-white text-base md:text-lg font-semibold text-center rounded-t-md py-2;
}

.cartContents {
  color: #282846;
  @apply max-w-4xl mx-auto py-5 px-2;
}

.cartSubTitles {
  @apply flex flex-row space-x-2 items-center;
}

.cartSubTitles img {
  @apply w-6 h-6;
}

.cartSubTitles div {
  @apply text-lg font-semibold;
}

.cartSubTitle {
  @apply text-lg font-semibold;
}

.cartItems {
  @apply flex flex-row text-base mb-5 w-full md:w-[440px];
}

.cartItems div:nth-child(2) {
  @apply w-[225px] md:w-[315px];
}

.cartRemarks {
  @apply text-base mb-5 w-full;
}

.cartRemarks textarea {
  color: #282846;
  border-color: #c7c7c7;
  @apply w-full min-h-[150px] text-base px-3 py-2 rounded-lg border-2;
}

.cartRemarks p {
  @apply whitespace-pre-wrap;
}

.cartItemName {
  color: #282846;
  background-color: #eeeeee;
  @apply w-24 md:w-28 h-7 text-base font-semibold rounded-lg mr-2 flex justify-center items-center;
}

.cartItemNameRemark {
  color: #282846;
  background-color: #eeeeee;
  @apply w-24 md:w-28 h-7 text-base font-semibold rounded-lg mr-2 hidden md:flex justify-center items-center;
}

.btnChange {
  @apply bg-cyan-600 hover:bg-cyan-500 text-white font-semibold px-4 py-1 rounded-md border-2 border-cyan-600 hover:border-cyan-500 mx-2;
}

.btnDelete {
  border-color: #c7c7c7;
  color: #282846;
  @apply bg-white hover:bg-gray-100 font-semibold px-4 py-1 rounded-md border-2 mx-2;
}

.cartFlight {
  background-color: #e5faff;
  @apply flex flex-row justify-between rounded-lg py-1.5 items-center mb-2.5;
}

.boxRight {
  border-color: #282846;
  color: #282846;
  @apply border-2 bg-amber-50 rounded-lg shadow-lg;
}

.boxRightTitle {
  background-color: #282846;
  @apply text-white text-lg font-semibold text-center rounded-t-md py-2;
}

.boxRightItemName {
  @apply w-20 font-semibold flex;
}

.boxRightChecked {
  color: #282846;
  @apply relative rounded-lg cursor-pointer border-2 text-base flex justify-center items-center px-8 py-3;
}

.boxRightHotelRank {
  color: #282846;
  @apply relative rounded-lg cursor-pointer border-2 text-base flex justify-center items-center px-3 md:px-8 py-4;
}

.boxRightCheckedFlight {
  color: #282846;
  @apply relative rounded-lg cursor-pointer border-2 text-base flex justify-center items-center;
}

.boxRightCheckedRestaurant {
  color: #282846;
  @apply relative rounded-lg cursor-pointer border-2 text-base flex justify-center items-center px-2 md:px-6 py-3;
}

.boxRightFlightImg {
  @apply w-28 bg-white flex justify-center items-center rounded-l-lg;
}

.boxRightFlightInfo {
  @apply flex flex-col px-2 md:px-8 py-3 w-[156px] md:w-[545px];
}

.boxRightFlightLabel {
  @apply flex flex-col md:flex-row md:items-center md:space-x-2 font-semibold text-base md:text-lg;
}

.boxRightFlightLabel div {
  @apply text-left md:text-center;
}

.boxRightFlightLabel svg {
  @apply w-6 h-6;
}

.boxRightOptionItems {
  @apply flex flex-row items-center space-x-2;
}

.boxRightOptionItems div:first-child {
  background-color: #eeeeee;
  @apply w-16 font-semibold rounded-lg flex justify-center items-center;
}

.boxRightOptionItems div:last-child {
  @apply w-[245px] md:w-[530px];
}

.boxRightOptionItems a {
  @apply w-[245px] md:w-[530px] text-cyan-600 hover:text-cyan-500 font-semibold underline flex flex-row space-x-2 items-center;
}

.orderInputArea {
  @apply bg-amber-50 p-2 md:p-5 space-y-5;
}

.orderInputNames {
  @apply flex flex-col md:flex-row space-y-5 md:space-y-0;
}

.orderInput {
  @apply relative;
}

.orderInput input {
  border-color: #c7c7c7;
  @apply border-2 focus:ring-slate-700 focus:border-slate-700 block px-4 py-3 sm:text-base rounded-lg;
}

.orderInputName {
  @apply w-full md:w-1/2 flex flex-row items-center;
}

.orderInputName label {
  @apply text-base font-semibold w-14 md:pl-4;
}

.orderInputH {
  @apply w-[280px] md:w-[350px];
}

.orderInputF {
  @apply w-full md:w-[350px];
}

.amountTitles {
  @apply flex flex-row justify-between text-sm md:text-base;
}

.amountItems {
  @apply flex flex-row items-center space-x-1 md:space-x-4 justify-end;
}

.cartFreeInput {
  border-color: #c7c7c7;
  @apply w-full md:w-[300px] mt-1 md:mt-0 px-3 py-2 border-2 placeholder-gray-500 rounded-lg focus:outline-none focus:ring-slate-700 focus:border-slate-700;
}

.cartFreeItems {
  @apply flex flex-col md:flex-row text-base mb-2.5 md:mb-5 w-full md:w-[440px] items-start md:items-center;
}
