@charset "UTF-8";
* {
    margin:0 ;
    padding:0 ;
    -webkit-box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    box-sizing: border-box !important; 
    }
/*----------------------------------common*/
body,html {
    max-width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    }
body{
	font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
	font-weight: normal;
    font-size:100%;
    word-break: break-all;
    color:#282846;
    background-color: #F8FAFC;
    }
a {
    text-decoration:none;
    cursor: pointer;
    display: block;
    }
a img,img{
    border:none;
    display: block;
}
ul,li{
    list-style:none;
}
.clearfix::after{
    content: "";
    display: block;
    clear: both;
    }
section{
    width:900px;
    margin:0 auto;
}
section.specialFull{
    width:100% !important;
    box-sizing: border-box;
}

/*----------------------------------header-footer*/
/*-------header-------*/
header{
    background-color:#0891B2;
}
    header div{
        width:900px;
        margin:0 auto;
        padding:12px 0;
    }
    header div a{
        width:300px;
    }
    header div img{
        height:30px;
    }
    header p{
        width:255px;
        font-size:90%;
        font-weight: bold;
        text-align: center;
        color:#fff;
    }
.specialHead{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
    .SHKansai,
    .SHChubu{
        background-image: url(../img/special-pack/bg_shinkansen_kansaichubu.jpg);
    }
    .SHTohoku{
        background-image: url(../img/special-pack/bg_shinkansen_tohoku.jpg);
    }
    .SHHokuriku,
    .SHShinshu{
        background-image: url(../img/special-pack/bg_shinkansen_hokurikushinshu.jpg);
    }
    .ARbg{
        background-image: url(../img/special-pack/bg_kokuken.jpg);
    }

/*-------footer-------*/
.backtop a{
    position: relative;
    margin:0 auto 40px auto;
    width:5em;
    height:5em;
    text-align: center;
    border-radius: 50%;
    background-color: #fff;
    color:#282846;
    font-weight: bold;
    filter: drop-shadow(0 6px 3px rgba(0,0,0,0.3));
}
    .backtop a p{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
        .backtop a p::before{
            display: block;
            content: "\025b2";
        }
footer{
    background-color:#0891B2;
}      
    .footLink{
        background-color: #fff;
    }
        .footLink ul{
            width:900px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
        }
        .footLink ul li{
            font-size:90%;
            padding:10px 0;
        }
        .footLink a{
            color:#282846;
        }
    .footInfo{
        width:900px;
        margin:0 auto;
        padding:20px 0 10px 0;
        font-size:80%;
        text-align: center;
        color: #fff;
    }
        .footInfo img{
            width:255px;
            margin: 0 auto;
        }
        .footInfo p{
            margin-top:20px;
        }

/*----------------------------------Title*/
.pageTitle{
    text-align: center;
    padding:20px 0;
}
    .pageTitle img{
        width:600px;
        margin:0 auto;
    }
.areaTitle{
    padding:5px 0;
    text-align: center;
    font-weight: bold;
    background-color:#CB312A;
    color:#fff;
}
section.pageDescript{
    padding:12px 0;
    margin-bottom:15px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(../img/special-pack/bg_yellow.png);
}
    .sfMain{
        text-align: center;
        font-weight: bold;
        font-size: 150%;
    }
        .sfMain p:nth-child(2){
            color:#CB312A;
            text-decoration: underline;
        }
    .sfText{
        width:900px;
        padding:10px;
        margin:10px auto;
        font-weight: bold;
        background-color: #fff;
        border-radius: 10px;
    }
    .sfText span.imp{
        color:#CB312A;
    }
    .sfText span.sub{
        font-size:80%;
        font-weight: normal !important;
    }

/*----------------------------------Table*/
article.tableWrap{
    margin-bottom:20px;
}
.tableWrapTitle{
    font-size:120%;
    color:#fff;
    background-color:#282846;
    border:3px solid #282846;
    border-radius: 10px 10px 0 0;
}
    .tableWrapTitle p{
        padding:5px;
        font-weight: bold;
    }
.tableSubInfo{
    box-sizing: border-box;
    width:80%;
    margin:0 auto;
    padding:0px 5px 8px;
    font-size:90% !important;
    font-weight: bold;
    color:#0891B2;
}
.tableSubFare{
    width:80%;
    margin:0 auto 8px auto;
    padding:5px;
    background-color:#EEE;
    font-weight: bold;
}
.tableWrapContent{
    width: auto;
    padding:10px 0 5px 0;
    border-radius: 0 0 10px 10px;
    background-color:#fff;
    border:3px solid #282846;
}
.tableWrapContent table{
    width:80%;    
    margin:0 auto 5px auto;
    box-sizing: border-box;
}
.tableWrapContent table,
.tableWrapContent table th,
.tableWrapContent table td,
.tableWrapContent table tr
{
    width: auto;
    border-collapse: collapse;
}
    .tableWrapContent table th,
    .tableWrapContent table td{
        border:3px solid #282846;
    }
    /*-----thead-----*/
    .tableWrapContent thead th{
        background-color:#EEEEEE;
        font-size:85%;
    }
        .tableWrapContent thead th:last-child{
            background-color:#F1E189;
        }
        .tableWrapContent table th span{
            display: block;
        }    
        .tableWrapContent thead.airList th{
            padding:8px 0;
        }
    /*-----tbody-----*/
    .tableWrapContent table tbody td{
        padding:8px;
    }
    .tableWrapContent table td.areaName,
    .tableWrapContent table td.routeName{
        text-align: center;
        font-weight: bold;
    }
    .tableWrapContent table td.areaName{
        font-size:130%;
        background-color: #0891B2;
        color:#fff;
    }
    .tableWrapContent table td.en,
    .tableWrapContent table td.enOtoku{
        text-align: right;
    }
        .tableWrapContent table td.enOtoku{
            font-weight: bold;
            font-size:150%;
            color:#ff0000;
        }
        .tableWrapContent table td.pack{
            font-weight: bold;
        }
        .tableWrapContent table td.en::after{
            content:"円";
        }
        .tableWrapContent table td.enOtoku::before{
            content:"最大で ";
            color:#000 !important;
            font-size:70% !important;
        }
            thead.airList + tbody td.enOtoku::before{
                content:"" !important;  
            }
        .tableWrapContent table td.enOtoku::after{
            content:" お得！";
            color:#000 !important;
            font-size:70% !important;
        }
        .tableWrapContent table td.enOtoku p{
            display:inline;           
        }
        .tableWrapContent table td.enOtoku p::after{
            content:"円";
        }   
    /*-----tbody empty-----*/
    .tableWrapContent table td:empty{
        text-align: center;
    }
        .tableWrapContent table td:empty::after{
            content:"-";
        }    
    /*-----tbody toiawase-----*/
    .tableWrapContent table tr.toiawaseWrap{
        text-align: center;
        font-weight: bold;
        background-color: #FFFBEB;
    }
        .tableWrapContent table tr.toiawaseWrap td{
            border:3px solid #FFFBEB;
            padding:0;
        }
        tr.toiawaseWrap a{
            position: relative;
            width:15em;
            margin:8px auto 3px auto;
            padding:8px 0;
            border-radius: 10px;
            font-size:110%;
            text-align: center;
            color:#282846;
            background-color: #F39233;
            filter: drop-shadow(0 6px 3px rgba(0,0,0,0.3));
        }
        tr.toiawaseWrap a::after{
            position: absolute;
            right:1em;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            content:url(../img/special-pack/icon_new-window.png);
        }
    .cautionWrap{
        margin:8px 0;
        padding:5px;
        font-weight: normal !important;
        font-size:80%;
        text-align: left !important;
        border:2px solid #C7C7C7;
        background-color:#fff;
        border-radius: 10px;
        overflow-y: scroll;
        height:10.5em;
    }
        .airCWP{
            height:12em !important;
            font-size:90%;
        }

/*-----home-----*/
.homeGLink ul{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size:80%;
    margin:10px 5px 12px 5px;
    text-align: center;
   }
   .homeGLink ul a{
       position: relative;
       width:7.5em;
       margin: 0 8px 10px 0;
       padding:10px 1em 10px 0;
       border-radius: 5px;
       filter: drop-shadow(0 6px 3px rgba(0,0,0,0.3));
       background:#fff;
       color: #282846;
       font-weight: bold;
       }
       .homeGLink ul a::after{
           position: absolute;
           right:10px;
           content:"\025bc";
       }
       .jumpHome a{
           width:12em;
           margin:0 auto 30px auto;
           padding:10px 0;
           border-radius: 10px;
           filter: drop-shadow(0 6px 3px rgba(0,0,0,0.3));
           background:#fff;
           color: #282846;
           font-weight: bold;    
           text-align: center;
       }
   .homeAreaTitle{
       margin:0 auto 10px auto;
       padding:5px 0;
       text-align: center;
       font-size: 120%;
       font-weight: bold;
       background-color:#EEEEEE;
   }
   


/*============================================res====*/
@media screen and  (max-width: 900px) {
    /*--------------SP Start--------------*/
    body{
        font-size:90%;
        }
    section{
        width:100%;
    }
    /*----------------------------------header-footer*/
    /*-------header-------*/
    header div{
        width:100%;
        padding:10px 0 8px 5px;
    }
    header div a{
        width:auto;
    }
    header div img{
        height:20px;
    }
    header p{
        width:180px;
        font-size:80%;
        text-align: left;
    }
    /*-------footer-------*/
    .footLink ul{
        width:100%;
        display: block;
        text-align: center;
    }
    .footInfo{
        width:100%;
        font-size:70%;
    }
        .footInfo img{
            width:200px;
        }
    /*----------------------------------Title*/
    .pageTitle{
        padding:15px 0;
    }
        .pageTitle img{
            width:310px;
        }
        section.pageDescript{
            padding:8px 0;
        }
            .sfMain{
                font-size: 130%;
            }
            .sfText{
                width:calc(100% - 10px); 
            }

    /*----------------------------------Table*/
    article.tableWrap{
        margin-bottom:15px;
    }
    .tableSubInfo{ 
        width:100%;
    }
    .tableSubFare{
        width:calc(100% - 10px);    
    }
    .tableWrapTitle{
        border:none;
    }
        .tableWrapTitle p{
            padding:8px;
        }
    .tableWrapContent{
        padding:5px 0 3px 0;
        border:none;
    }
    .tableWrapContent table{
        width:calc(100% - 10px);    
        margin:0 auto 5px auto;
        position: relative;
    }
        .tableWrapContent table th,
        .tableWrapContent table td{
            border:2px solid #282846;
    }
    /*-----thead-----*/
    .tableWrapContent thead th{
        background-color:#EEEEEE;
        font-size:85%;
    }
        .tableWrapContent thead.airList th{
            padding:63px 0 8px 0;
        }
        .tableWrapContent table th span{
            display: block;
        }    
    /*-----tbody-----*/
    .tableWrapContent table tbody td{
        padding:8px;
    }
    .tableWrapContent table td.areaName,
    .tableWrapContent table td.routeName{
        text-align: center;
        font-weight: bold;
    }
    .tableWrapContent table td.areaName{
        font-size:130%;
        background-color: #0891B2;
        color:#fff;
    }
    .tableWrapContent table td.enOtoku{
        text-align: center !important;
    }
    thead.airList + tbody td.enOtoku::before{
        content:"最大で" !important;  
    }
    .tableWrapContent table td.en,
    .tableWrapContent table td.enOtoku,
    .tableWrapContent table td.enOtoku p{
        word-break: keep-all;
    }
        .tableWrapContent table tr.toiawaseWrap td{
            border:2px solid #FFFBEB;
            border-top:2px solid #282846;
            padding:0;
        }
        tr.toiawaseWrap a{
            width:12em;
        }
    .cautionWrap{
        border-radius: 0;
        height:12em !important;
    }
/*--------------SP End--------------*/
}/*SP toji*/    


@media screen and  (max-width: 320px) {
/*--------------iPhone4 Start--------------*/
.tableWrapContent table td.en,
.tableWrapContent table td.enOtoku{
    word-break: break-all;
}
/*--------------iPhone4 End--------------*/        
}/*iPhone4 toji*/    

    
