.guideOrderTitle {
  color: #282846;
  @apply bg-slate-200 focus:outline-none flex w-full justify-between px-4 py-2 text-left text-sm font-semibold hover:opacity-95;
}

.guideOrderPanel {
  color: #282846;
  @apply flex flex-col space-y-5 bg-white px-4 pt-4 pb-2 text-sm;
}

.guideOrderPanel span {
  @apply text-red-400 font-semibold;
}

.guideOrderPanelImgs {
  @apply flex flex-row justify-evenly items-start;
}

.guideOrderPanelImgs img {
  @apply w-[450px];
}

@media screen and (max-width: 767px) {
  .guideOrderPanelImgs {
    @apply flex flex-col justify-evenly items-start;
  }

  .guideOrderPanelImgs img {
    @apply w-full my-2.5;
  }
}

.guideJumpLink {
  color: #282846;
  border-color: #c7c7c7;
  @apply bg-white hover:bg-gray-100 w-40 font-semibold py-4 px-4 flex flex-col justify-center items-center border-2 cursor-pointer;
}

.guideJumpLink img {
  @apply w-8 h-8 mb-2.5;
}
