.progressbar {
  @apply flex flex-row;
}
.progressbar .item {
  width: 33%;
  padding: 13px 0;
  @apply relative
    bg-gray-100
    text-slate-400
    text-center
    items-center
    content-center
    leading-6
    text-base
    font-semibold;
}
.progressbar :nth-child(2),
.progressbar :nth-child(3) {
  padding-left: 1rem;
}
.progressbar .item:not(:last-child)::before,
.progressbar .item:not(:last-child)::after {
  content: "";
  border: 37px solid transparent;
  border-left: 20px solid #f3f4f6;
  @apply absolute
    z-20
    top-0
    bottom-0
    left-full
    m-auto;
}
.progressbar .item:not(:last-child)::before {
  margin-left: 1px;
  border-left-color: #fff;
}

@media screen and (max-width: 767px) {
  .progressbar .item {
    padding: 10px 0;
    @apply text-xs;
  }
  .progressbar .item:not(:last-child)::before,
  .progressbar .item:not(:last-child)::after {
    border-width: 25px;
    border-left-width: 12px;
  }
  .progressbar :nth-child(2),
  .progressbar :nth-child(3),
  .progressbar :nth-child(4),
  .progressbar :nth-child(5) {
    padding-left: 1rem;
  }
}

/* active */
.progressbar .item.active {
  @apply z-10 text-white bg-cyan-600;
}
.progressbar .item.active:not(:last-child)::after {
  @apply border-l-cyan-600;
}
.progressbar .item.active:not(:last-child)::before {
  @apply border-l-0;
}
