/*
#FFFFFF :コンテンツ背景色ほか bg-white
#707070 :テキスト入力エリアデフォルト文言色 text-dGray
#C7C7C7 :非アクティブボタンborder色ほか bg-gray
#EEEEEE :非アクティブボタン背景色ほか bg-lGray
#F8FAFC :body背景色
#F39233 :決定ボタン背景色ほか btn-enter
#FFFBEB :編集エリア背景色ほか bg-amber-50
#F1E189 :選択中ボタン背景色ほか bg-orange-lights
#0891B2 :ヘッダほか bg-cyan-600
#E5FAFF :プラン作成画面 往路・復路タイトル背景色 border-orange-light
#282846 :メイン文字色ほか text-color
#F1E189から#F39233 :TOPページのコンセプト説明欄の背景グラデーション

margin 10px m-2.5
margin 20px m-5
margin 35px m-9 36px
margin 40px m-10
margin 60px m-16 64px

border 3px border-2 2px;border-4 4px;
border-radius 10px rounded-lg 8px rounded-xl 12px
・最大(決定ボタン) :25pt 4xl 36px
・見出し :20pt  3xl 30px
・中見出しや目立たせたい情報:18pt 2xl 24px
・本文 :15pt xl 20px

*/

.checkIcon {
  color: #f39233;
  width: 1.5rem;
  height: 1.5rem;
}

.btn-enter {
  background-color: #f39233;
  color: #282846;
  @apply w-full md:w-96 text-xl py-4 px-1 flex justify-center items-center hover:opacity-90 font-black rounded-lg shadow-md;
}

.btn-back {
  border-color: #c7c7c7;
  color: #282846;
  @apply bg-white border-2 w-full md:w-96 text-xl py-4 px-1 flex justify-center items-center hover:opacity-90 font-black rounded-lg shadow-md;
}

.text-color {
  color: #282846;
}

.text-dGray {
  color: #707070;
}

.text-lGray {
  color: #c7c7c7;
}

.text-orange {
  color: #f39233;
}

.border-dGray {
  border-color: #282846;
}

.border-gray {
  border-color: #c7c7c7;
}
.bg-dGray {
  background-color: #282846;
}

.bg-lGray {
  background-color: #eeeeee;
}

.bg-lBlue {
  background-color: #e5faff;
}

.mt-35p {
  margin-top: 35px;
}

.mb-60p {
  margin-bottom: 60px;
}

.bg-orange-light {
  background-color: #f1e189;
}

.bg-lightBlue-light {
  background-color: #e5faff;
}

.border-orange-light {
  --tw-border-opacity: 1;
  border-color: rgb(241 225 137 / var(--tw-border-opacity));
}

.from-yellow {
  --tw-gradient-to: #f39233;
}

.to-orange {
  --tw-gradient-from: #f1e189;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgb(251 146 60 / 0));
}
