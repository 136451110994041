@tailwind base;
@tailwind components;
@tailwind utilities;

.menu {
  @apply text-base
    font-medium
    text-white
    hover:text-sky-900;
}

.logIn {
  @apply ml-8
    whitespace-nowrap
    inline-flex
    items-center
    justify-center
    px-4
    py-2
    border
    border-transparent
    rounded-md
    shadow-sm
    text-base
    font-medium
    text-white
    bg-cyan-500
    hover:text-cyan-900;
}

.signUp {
  @apply ml-8
    whitespace-nowrap
    inline-flex
    items-center
    justify-center
    px-4
    py-2
    border
    border-transparent
    rounded-md
    shadow-sm
    text-base
    font-medium
    text-gray-400
    bg-white
    hover:text-cyan-900;
}

.menuIcon {
  @apply bg-cyan-600
  rounded-md
  p-2
  inline-flex
  items-center
  justify-center
  text-gray-50
  hover:text-gray-500
  hover:bg-cyan-700
  focus:outline-none
  focus:ring-2
  focus:ring-inset
  focus:ring-cyan-500;
}

.menuSP {
  @apply text-base
    font-medium
    text-cyan-900;
}
