.scrollBtn {
  @apply absolute left-1/2 mt-20 -ml-36 bg-white rounded-lg cursor-pointer shadow-xl;
}
.scrollBtn :hover {
  @apply rounded-lg;
  background-color: #f39233;
}

.scrollArrow {
  @apply w-4 h-auto rotate-90;
}

.subTitle {
  text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
    1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff;
  color: #282846;
  @apply absolute left-1/2
    font-semibold text-lg;
}
.subTitle1 {
  @apply mt-1 -ml-[150px] md:-ml-48 text-sm md:text-lg;
}

.subTitle2 {
  @apply mt-6 md:mt-8 -ml-[120px] md:-ml-64 text-xl md:text-2xl text-center;
}

.contentBlock {
  @apply bg-white rounded-lg p-4 sm:p-8 space-y-4 sm:space-y-6;
}

.contentTitle {
  @apply text-xl font-semibold;
}

.contentSub {
  @apply text-sm sm:text-base leading-loose break-all;
}

.scrollBtnBottom {
  @apply bg-white rounded-lg cursor-pointer shadow-xl mb-10;
}
.scrollBtnBottom :hover {
  @apply rounded-lg bg-cyan-600 text-white;
}

.scrollArrowGray {
  @apply w-4 h-auto -rotate-90;
}

/* 2023.06.23 */
.spLinkBtn{
  display: block;
  margin: 1rem auto;
}
.spLinkBtn img{
  margin: 0 auto;
}

.abroadLink{
  display: flex;
  margin-top:20px;
  text-align: center;
  font-weight: bold;
  background: #f1e189;
  & a{
      position: relative;
      box-sizing: border-box;
      width:100%;
      padding:5px;
      cursor: pointer;
      & span{
          display: inline-block;
      }
      & span:first-child{
          background:#f39233;
          color:#fff;
          padding:2px 4px;
          margin-right: 1em;
      }
  }
}
.abroadLink:hover{
  transition: 0.3s;
  background: #fff7c9;
  & span:nth-child(2){
          color:#f39233;
      }
}