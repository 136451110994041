.steps {
  color: #282846;
  @apply w-full bg-white rounded-lg flex flex-col items-stretch md:flex-row space-y-2 md:space-y-0;
}

.stepNumber {
  background-color: #282846;
  @apply text-white rounded-t-lg md:rounded-t-none md:rounded-l-lg w-full md:w-36 p-2 md:p-1 flex flex-row md:flex-col items-center justify-center md:justify-start font-semibold space-x-4 md:space-x-0;
}

.stepNumber span {
  @apply text-sm mr-1;
}

.stepSelect {
  @apply w-full py-2.5 px-2 md:px-8;
}

.stepSelectText {
  @apply block text-sm md:text-base font-semibold;
}

.selectArea {
  @apply flex flex-col md:flex-row items-center;
}

.selectLabel {
  @apply font-semibold w-full md:w-[60px] text-left;
}

.areasOption {
  @apply w-full flex flex-row flex-wrap justify-start space-x-0 md:space-x-2;
}

.selectBtn {
  @apply flex justify-center items-center mt-2 md:mt-0 mr-2 md:mr-0 w-[160px] md:w-[145px] h-[56px] relative rounded-lg shadow-md px-1 md:px-2 py-1.5 cursor-pointer focus:outline-none border-2;
}

.selectDates {
  @apply w-full md:w-[355px] flex flex-col md:flex-row items-start md:items-center;
}

.selectDate {
  @apply relative w-full md:w-[300px];
}

.inputBtnText {
  color: #282846;
  border-color: #c7c7c7;
  @apply flex justify-between w-full md:w-[290px] mt-2 md:mt-0 px-3 py-2 text-base bg-white hover:bg-gray-50 border-2 rounded-lg shadow-sm;
}

.rankStar {
  @apply flex-shrink-0 w-4 md:w-5 h-4 md:h-5;
}

.selectedDepartureTimes {
  color: #282846;
  @apply flex flex-col justify-center items-center mt-2 md:mt-0 mr-2.5 md:mr-0 w-[160px] md:w-[137px] h-[56px] relative rounded-lg shadow-md p-1.5 cursor-pointer focus:outline-none border-2;
}

.calChangeMonth {
  color: #282846;
  border-color: #c7c7c7;
  @apply inline-flex p-1 text-sm font-medium bg-white border-2 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-slate-800;
}

.transportArea {
  opacity: 0;
  animation: fadeIn 0.6s linear;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* calender */
.react-datepicker__input-container input {
  @apply block w-full text-base md:text-sm bg-white border-2 border-gray-300 rounded shadow-sm;
}

.react-datepicker-popper {
  @apply z-40 w-80 md:w-72 text-sm bg-white shadow px-3 py-2 border-2 border-gray-200 rounded;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute z-10 w-72 text-sm transform-none bg-white shadow px-3 py-2 top-12 right-0 border-2 border-gray-200 rounded;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-400 font-medium text-center text-xs;
}
.react-datepicker__day-names :first-child {
  @apply flex justify-around text-red-400 font-medium text-center text-xs;
}

.react-datepicker__day-names :last-child {
  @apply flex justify-around text-blue-400 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-base md:text-sm leading-loose transition text-gray-700 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-300;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200 font-semibold;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-amber-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-amber-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-amber-500;
}

.react-datepicker__day--selected {
  background-color: #f1e189;
  color: #282846;
  font-weight: 600;
}

.react-datepicker__day--range-start {
  background-color: #f1e189;
  color: #282846;
  @apply hover:text-gray-700 hover:bg-white font-semibold;
}

.react-datepicker__day--range-end {
  background-color: #f1e189;
  color: #282846;
  @apply hover:text-gray-700 hover:bg-white font-semibold;
}

.selectNop + .selectLabel{
  margin-top:8px;
}

@media (min-width: 768px) {
  .selectNop + .selectLabel{
    margin-top:0;
    margin-left:32px;
  }
}

@media (min-width: 768px) {
  .flex-mbtn{
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    row-gap:8px;
  }
}

.btn-allclear{
  background-color:#fff !important;
  border:2px solid #c7c7c7;
  margin-top:20px;
}